import { LocationType } from "@freightsimple/generated-dashboard-openapi-client";

export function errorMessageForDistributionWarehouseBrand(
  locationType: LocationType,
  distributionWarehouseBrand: string | null | undefined,
) {
  if (locationType === LocationType.DistributionWarehouse) {
    if (distributionWarehouseBrand === undefined) {
      return "Required";
    }
  }
  return undefined;
}
