import Dropdown from "../../../../Cargo/Controls/Dropdown";
import { ErrorMessageType } from "../../../../Cargo/Validation";
import distributionWarehouseBrands from "../../../../GeneratedFixtures/DistributionWarehouseBrands.json";

interface DistributionWarehouseBrandDropdownProps {
  selectedItem: string | null | undefined;
  onOptionSelected: (option: string | undefined) => void;
  errorMessage: ErrorMessageType;
  forceValidation: boolean;
}

function getDistributionWarehouseBrandOptions() {
  const brands = distributionWarehouseBrands;
  return [
    ...brands
      .map((brand) => ({
        item: brand.identifier,
        description: brand.displayName,
      }))
      .sort((a, b) => a.description.localeCompare(b.description)),
    { item: "other", description: "Other" },
  ];
}

export function DistributionWarehouseBrandDropdown(
  props: DistributionWarehouseBrandDropdownProps,
) {
  const options = getDistributionWarehouseBrandOptions();
  return (
    <Dropdown
      selectedItem={props.selectedItem ?? undefined}
      onOptionSelected={props.onOptionSelected}
      options={options}
      width={349}
      errorMessage={props.errorMessage}
      forceValidation={props.forceValidation}
      // Not measured
      maxDropdownHeight={(options.length * 48.5 + 125).toFixed(0) + "px"}
      unselectedDescription={<span>Select a brand</span>}
    />
  );
}
