// TODO we should generate this file
import locationTypes from "../GeneratedFixtures/LocationTypes.json";
import distributionWarehouseBrands from "../GeneratedFixtures/DistributionWarehouseBrands.json";
import { LocationType } from "@freightsimple/generated-dashboard-openapi-client";

export interface LocationTypeDescription {
  name: string;
  type: LocationType;
  description: string;
}

export function describeLocationType(
  locationType: LocationType,
  distributionWarehouseBrand: string | undefined,
): string {
  const locationTypeDescription =
    locationTypes.find((lt) => lt.type == locationType)?.name ?? locationType;
  if (locationType === LocationType.DistributionWarehouse) {
    const brand = distributionWarehouseBrands.find(
      (brand) => brand.identifier === distributionWarehouseBrand,
    )?.displayName;
    if (brand) {
      return `${locationTypeDescription} (${brand})`;
    }
  }
  return locationTypeDescription;
}
