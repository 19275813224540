import { ErrorMessageType } from "../../../Cargo/Validation";
import { AddLocationLocationType } from "../Types/locationTypes";
import {
  Location,
  PreQuoteLocation,
} from "@freightsimple/generated-dashboard-openapi-client";
import { errorMessageForBusinessName } from "./errorMessageForBusinessName";
import { errorMessageForHours } from "./errorMessageForHours";
import { errorMessageForLocationType } from "./errorMessageForLocationType";
import {
  AddressErrors,
  errorMessagesForAddress,
} from "./errorMessagesForAddress";
import { errorMessageForDistributionWarehouseBrand } from "./errorMessageForDistributionWarehouseBrand";

export interface LocationErrors {
  businessName: ErrorMessageType;
  address: AddressErrors;
  locationType: ErrorMessageType;
  hours: ErrorMessageType;
  distributionWarehouseBrand: ErrorMessageType;
}

export function errorMessagesForLocation(
  location: AddLocationLocationType | Location | PreQuoteLocation,
  checkHoursAreValidForAPickupToday: boolean,
): LocationErrors {
  const allowAfterHours = location.accessorials.includes(
    "SCHEDULING_AFTER_HOURS",
  );
  return {
    businessName: errorMessageForBusinessName(location.businessName),
    address: errorMessagesForAddress(location.address),
    locationType: errorMessageForLocationType(location.locationType),
    hours: errorMessageForHours(
      location.hours,
      allowAfterHours,
      checkHoursAreValidForAPickupToday,
    ),
    distributionWarehouseBrand: errorMessageForDistributionWarehouseBrand(
      location.locationType,
      location.distributionWarehouseBrand,
    ),
  };
}
