import {
  NormalQuestionBubble,
  QuestionBubbleHeader,
  QuestionBubbleLabel,
} from "../../../../Cargo/Controls/QuestionBubble";

function DistributionWarehouseBrandQuestionBubble() {
  return (
    <NormalQuestionBubble
      description={
        <>
          <QuestionBubbleHeader>
            {`What distribution warehouse brand is this address?`}
          </QuestionBubbleHeader>
          <QuestionBubbleLabel>
            Make sure you enter the correct distribution warehouse brand, as it
            affects the quote, and helps us determine which carrirs can
            correctly service this shipment.
          </QuestionBubbleLabel>
          <QuestionBubbleLabel>
            Incorrectly specifying this may lead to delays and additional
            charges
          </QuestionBubbleLabel>
        </>
      }
    />
  );
}
export default DistributionWarehouseBrandQuestionBubble;
