import Link from "../../../../Cargo/Controls/Link";
import Spacer from "../../../../Cargo/Layout/Spacer";
import Stack from "../../../../Cargo/Layout/Stack";
import { nameAccessorials } from "../../../../Data/AccessorialTypes";
import { formatHandlingUnitTypeWithQuantity } from "../../../../Data/HandlingUnitTypes";
import { describeLocationType } from "../../../../Data/LocationTypes";
import { describeHazardClass } from "../../../DangerousGoods/Helpers/describeHazardClass";
import { AddLineItemType } from "../../../LineItems/Types/lineItemTypes";
import { AddLocationLocationType } from "../../../Locations/Types/locationTypes";
import { formatLocation } from "./Helpers/formatLocation";
import { describeHours } from "../../../../Helpers/describeHours";
import { formatPhone } from "../../../../Helpers/formatPhone";
import { isDomesticCanada } from "../../../../Helpers/isDomesticCanada";
import {
  totalWeightForLineItem,
  totalWeightForLineItems,
} from "../../../../Helpers/lineItemCalculations";
import {
  Contact,
  LineItem,
  Location,
  LocationContext,
  PreQuoteLocation,
  ShipmentState,
  TemperatureHandling,
} from "@freightsimple/generated-dashboard-openapi-client";
import { EquipmentType } from "@freightsimple/generated-dashboard-openapi-client";
import {
  Column,
  ColumnHeader,
  ColumnSectionHeader,
  LineItemText1,
  LineItemText2,
  LocationTypeDescription,
  ModifyLink,
  NotesSection,
  PrimaryText,
  SecondaryText,
} from "./QuoteRowSharedStyles";
import moment from "moment";

interface PickupColumnProps {
  pickupDate: moment.Moment;
  pickupDeadline?: string | undefined;
  pickupLocation: Location | AddLocationLocationType | PreQuoteLocation;
  pickupContact: Contact | undefined;
  lineItems: Array<LineItem | AddLineItemType>;
  quote: { carrierIdentifier: string; serviceDisplayName: string } | undefined;
  shipmentState: ShipmentState;
  pickupReferenceNumber?: string;
  pickupBoothNumber?: string;
  notes?: string;
  equipmentType?: EquipmentType | undefined;
  exclusiveUseNeeded?: boolean;
  tarpRequired?: boolean;
  linearFeet?: number;
  onModifyDetails?: () => void;
  onChangePickupDate?: () => void;
  onModifyPickupAddress?: () => void;
  onModifyPickupContact?: () => void;
  onModifyPickupReferenceAndNotes?: () => void;
  isDomesticCanada: boolean;
  showNotesSection: boolean;
  // Is this in a two column layout?
  twoColumn?: boolean;
  hidePickupHours?: boolean;
}

function WhenSection(props: PickupColumnProps) {
  const { pickupLocation, pickupDate, pickupDeadline } = props;

  const formattedDate = pickupDate.format("dddd Do MMMM YYYY");
  const windowDescription = pickupLocation.hours
    ? `Pickup window: ${describeHours(pickupLocation.hours)}`
    : undefined;

  const onHold = props.shipmentState === ShipmentState.OnHold;

  const formattedPickupDeadlineDate = props.pickupDeadline
    ? moment(pickupDeadline).format("dddd Do MMMM YYYY")
    : "";

  return (
    <>
      <ColumnSectionHeader>When</ColumnSectionHeader>
      {!onHold && <PrimaryText>{formattedDate}</PrimaryText>}
      {onHold && <PrimaryText>On Hold</PrimaryText>}
      {!onHold && !props.hidePickupHours && windowDescription && (
        <SecondaryText>{windowDescription}</SecondaryText>
      )}
      {(props.shipmentState === ShipmentState.Quoted ||
        props.shipmentState === ShipmentState.BookingConfirmed) &&
        props.onChangePickupDate && (
          <ModifyLink>
            <Link onClick={props.onChangePickupDate}>Change pickup date</Link>
          </ModifyLink>
        )}

      {pickupDeadline && !onHold && (
        <>
          <ColumnSectionHeader>Pickup Deadline</ColumnSectionHeader>
          <PrimaryText>{formattedPickupDeadlineDate}</PrimaryText>
        </>
      )}
    </>
  );
}

function WhereSection(props: PickupColumnProps) {
  const { pickupLocation } = props;

  const locationTypeDescription = describeLocationType(
    pickupLocation.locationType,
    pickupLocation.distributionWarehouseBrand,
  );
  const formattedLocation = formatLocation(pickupLocation);

  if (pickupLocation.businessName === undefined) {
    return (
      <>
        <ColumnSectionHeader>Where</ColumnSectionHeader>
        <PrimaryText>{formattedLocation}</PrimaryText>
        <SecondaryText>{locationTypeDescription}</SecondaryText>
      </>
    );
  } else {
    return (
      <>
        <ColumnSectionHeader>Where</ColumnSectionHeader>
        <PrimaryText>{pickupLocation.businessName}</PrimaryText>
        <SecondaryText>
          Location Type:{" "}
          <LocationTypeDescription>
            {locationTypeDescription}
          </LocationTypeDescription>
        </SecondaryText>
        <SecondaryText>{pickupLocation.address.addressLine}</SecondaryText>
        <SecondaryText>{pickupLocation.address.addressLine2}</SecondaryText>
        <SecondaryText>{formattedLocation}</SecondaryText>
        {props.onModifyPickupAddress && (
          <ModifyLink>
            <Link onClick={props.onModifyPickupAddress}>Change address</Link>
          </ModifyLink>
        )}
      </>
    );
  }
}

function ContactSection(props: PickupColumnProps) {
  const { pickupContact } = props;

  if (pickupContact?.contactName === undefined) {
    return <></>;
  } else {
    return (
      <>
        <ColumnSectionHeader>Contact</ColumnSectionHeader>
        <PrimaryText>{pickupContact?.contactName}</PrimaryText>
        <SecondaryText>{pickupContact?.emailAddress}</SecondaryText>
        <SecondaryText>
          {formatPhone(
            pickupContact?.phoneNumber as string,
            pickupContact?.phoneNumberExtension,
          )}
        </SecondaryText>
        {props.onModifyPickupContact && (
          <ModifyLink>
            <Link onClick={props.onModifyPickupContact}>Change contact</Link>
          </ModifyLink>
        )}
      </>
    );
  }
}

function SpecialServicesSection(props: PickupColumnProps) {
  const { pickupLocation } = props;

  const lines = nameAccessorials(
    pickupLocation.accessorials,
    LocationContext.Pickup,
    pickupLocation.locationType,
  );

  return (
    <>
      <ColumnSectionHeader>Special Services</ColumnSectionHeader>
      <ul style={{ paddingInlineStart: "20px", marginBottom: "0" }}>
        {lines.map((line, index) => {
          return (
            <li key={index}>
              <SecondaryText>{line}</SecondaryText>
            </li>
          );
        })}
      </ul>
      {props.onModifyDetails && (
        <>
          <Spacer height={8} />
          <ModifyLink>
            <Link onClick={props.onModifyDetails}>Modify special services</Link>
          </ModifyLink>
        </>
      )}
    </>
  );
}

function EquipmentSection(props: PickupColumnProps) {
  const { equipmentType, linearFeet, exclusiveUseNeeded, tarpRequired } = props;

  function describeEquipmentType() {
    switch (equipmentType) {
      case EquipmentType.DryVan:
        return "Dry Van";
      case EquipmentType.FlatBed:
        return "Flatbed";
      case EquipmentType.Reefer:
        return "Temperature Controlled";
    }

    // should not be here
    throw new Error("Invalid equipment type");
  }

  function describeEquipment() {
    const etDescription = describeEquipmentType();
    const sharedDescription = exclusiveUseNeeded ? "Dedicated" : "Shared";
    const linearFeetDescription = exclusiveUseNeeded
      ? ""
      : `, ${linearFeet}ft required`;
    const tarpDescription =
      equipmentType === EquipmentType.FlatBed
        ? tarpRequired
          ? " (Tarp Required)"
          : " (Tarp Not Required)"
        : "";

    return `${sharedDescription} ${etDescription}${tarpDescription}${linearFeetDescription}`;
  }

  if (props.equipmentType === undefined) {
    return <></>;
  }

  return (
    <>
      <ColumnSectionHeader>Equipment</ColumnSectionHeader>
      <SecondaryText>{describeEquipment()}</SecondaryText>
    </>
  );
}

function PayloadSection(props: PickupColumnProps) {
  const { lineItems } = props;

  const formattedLineItems = lineItems.map((li) => {
    const secondary = `${formatHandlingUnitTypeWithQuantity(
      li.numberHandlingUnits,
      li.handlingUnitType,
    )} ${li.description}`;

    let tertiary = `${li.length}" x ${li.width}" x ${li.height}"`;

    if (lineItems.length > 1) {
      tertiary += ` - ${totalWeightForLineItem(li)}lb`;
    }

    if (li.isStackable) {
      tertiary += ` - Stackable`;
    } else {
      tertiary += ` - Not Stackable`;
    }

    let freightClass = undefined;

    if (li.temperatureHandling === TemperatureHandling.ProtectFromFreezing) {
      tertiary += ` - Protect From Freezing`;
    }

    if (li.temperatureHandling === TemperatureHandling.KeepRefrigerated) {
      tertiary += ` - Keep Refrigerated`;
    }

    if (li.temperatureHandling === TemperatureHandling.KeepFrozen) {
      tertiary += ` - Keep Frozen`;
    }

    if (li.freightClass && !isDomesticCanada) {
      freightClass = `Freight class ${li.freightClass}`;
    }

    let dangerous = undefined;

    if (li.isDangerous && li.dangerousClassification) {
      dangerous = `Dangerous: ${
        li.dangerousUnNumber
      }, ${describeHazardClass(li.dangerousClassification)}`;
    }

    let nmfcItemNumber = undefined;

    if (li.nmfcItemNumber) {
      nmfcItemNumber = `NMFC: ${li.nmfcItemNumber}`;
    }

    return {
      secondary,
      tertiary,
      freightClass,
      nmfcItemNumber,
      dangerous,
    };
  });

  return (
    <>
      <ColumnSectionHeader>Payload</ColumnSectionHeader>
      <PrimaryText>
        Total Weight: {totalWeightForLineItems(props.lineItems)}lb
      </PrimaryText>
      {formattedLineItems.map((li, index) => {
        return (
          <Stack align="left" key={index} style={{ marginBottom: "4px" }}>
            <LineItemText1>{li.secondary}</LineItemText1>
            <LineItemText2>{li.tertiary}</LineItemText2>
            {li.freightClass && (
              <LineItemText2>{li.freightClass}</LineItemText2>
            )}
            {li.nmfcItemNumber && (
              <LineItemText2>{li.nmfcItemNumber}</LineItemText2>
            )}
            {li.dangerous && <LineItemText2>{li.dangerous}</LineItemText2>}
          </Stack>
        );
      })}
      <Spacer height={4} />
      {/* <LinkButton regularWeight={true} style={{ fontSize: '10px' }}>
                View List Of Prohibited Goods
            </LinkButton> */}

      {props.onModifyDetails && (
        <ModifyLink>
          <Link onClick={props.onModifyDetails}>Modify contents</Link>
        </ModifyLink>
      )}
    </>
  );
}

function PickupColumn(props: PickupColumnProps) {
  return (
    <Column $twoColumn={props.twoColumn}>
      <Stack align="left">
        <ColumnHeader>Pickup</ColumnHeader>
        <WhenSection {...props} />
        <WhereSection {...props} />
        <ContactSection {...props} />
        <PayloadSection {...props} />
        {props.showNotesSection && (
          <NotesSection
            reference={props.pickupReferenceNumber}
            boothNumber={props.pickupBoothNumber}
            notes={props.pickupLocation.notes}
            locationType={props.pickupLocation.locationType}
            onModify={props.onModifyPickupReferenceAndNotes}
          />
        )}
        <SpecialServicesSection {...props} />
        <EquipmentSection {...props} />
      </Stack>
    </Column>
  );
}
export default PickupColumn;
