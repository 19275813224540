import Button from "../../../Cargo/Controls/Button";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import { Legalese, ModalTitle } from "../../../Cargo/Text/Text";
import AddressInput from "../../Locations/Components/AddressInput";
import { errorMessagesForLocation } from "../../Locations/Validators/errorMessagesForLocation";

import { Location } from "@freightsimple/generated-dashboard-openapi-client";
import { LocationContext } from "@freightsimple/generated-dashboard-openapi-client";
import { useState } from "react";

interface ModifyAddressModalProps {
  location: Location;
  onDone: (updatedLocation: Location) => void;
  onCancel: () => void;
  locationContext: LocationContext | undefined;
}

export function ModifyAddressModal(props: ModifyAddressModalProps) {
  const [location, setLocation] = useState(props.location);
  const [forceValidation, setForceValidation] = useState(false);
  const locationErrorMessages = errorMessagesForLocation(location, false);

  function isValid() {
    if (locationErrorMessages.address.addressLine !== undefined) {
      return false;
    }

    if (locationErrorMessages.businessName !== undefined) {
      return false;
    }

    return true;
  }

  function onDone() {
    if (isValid()) {
      props.onDone(location);
    } else {
      setForceValidation(true);
    }
  }

  return (
    <div style={{ width: "520px" }}>
      <ModalTitle>Change Address</ModalTitle>
      <Legalese>
        Enter updated details for this shipment. Changes will also be saved to
        your address book
      </Legalese>
      <Spacer height={8} />
      <AddressInput
        enabled={true}
        businessName={location.businessName || ""}
        businessNameChanged={function (newBusinessName) {
          setLocation({
            ...location,
            businessName: newBusinessName,
          });
        }}
        locationType={location.locationType}
        distributionWarehouseBrand={location.distributionWarehouseBrand}
        address={location.address}
        addressChanged={function (newAddress) {
          const newLocation = {
            ...location,
            address: {
              ...location.address,
              ...newAddress,
            },
          };
          setLocation(newLocation);
        }}
        forceValidation={forceValidation}
        businessNameErrorMessages={locationErrorMessages.businessName}
        addressErrorMessages={locationErrorMessages.address}
        addressOptions={[]}
        allowCityAndPostalCodeEditing={false}
        warnAboutPotentialBusinessNameErrors={true}
      />
      <Spacer height={24} />
      <HorizontalStack width="100%" align="spread">
        <Button secondary onClick={props.onCancel}>
          Cancel
        </Button>

        <Button onClick={onDone}>Done</Button>
      </HorizontalStack>
    </div>
  );
}
