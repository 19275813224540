import MiniMap from "../../../../Cargo/Controls/MiniMap";
import Box from "../../../../Cargo/Layout/Box";
import { BoxStyle } from "../../../../Cargo/Layout/BoxStyle";
import HorizontalStack from "../../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../../Cargo/Layout/Spacer";
import Stack from "../../../../Cargo/Layout/Stack";
import { nameAccessorialsExplicitly } from "../../../../Data/AccessorialTypes";
import { describeLocationType } from "../../../../Data/LocationTypes";
import { AddLocationLocationType } from "../../../Locations/Types/locationTypes";
import { LocationContext } from "@freightsimple/generated-dashboard-openapi-client";
import { describeHours } from "../../../../Helpers/describeHours";
import { fullStateOrProvinceName } from "../../../../Helpers/fullStateOrProvinceName";
import styled from "styled-components";

const BusinessName = styled.div`
  font-weight: var(--nhu-font-weight-medium);
  font-size: 18px;
  color: var(--freightsimple-color-normal-text);
`;

const SectionLabel = styled.div`
  font-weight: var(--nhu-font-weight-bold);
  font-size: 12px;
  color: var(--freightsimple-color-normal-text);
`;
const SectionLine = styled.div`
  font-weight: var(--nhu-font-weight-light);
  font-size: 12px;
  color: var(--freightsimple-color-light-text);
`;

interface LocationBox2Props {
  location: AddLocationLocationType;
  context: LocationContext;
}
function LocationBox2(props: LocationBox2Props) {
  const { businessName, address } = props.location;

  const { postalCode, stateOrProvinceCode, city } = props.location.address;

  const accessorials = nameAccessorialsExplicitly(
    props.location.accessorials,
    props.context,
    props.location.locationType,
  );

  const hoursDescription = describeHours(props.location.hours);
  const locationTypeDescription = describeLocationType(
    props.location.locationType,
    props.location.distributionWarehouseBrand,
  );

  const latlng = props.location.latitudeLongitude;

  if (latlng === undefined) {
    return <></>;
  }

  return (
    <Box
      width={808}
      style={{ minHeight: "230px", padding: "32px" }}
      boxStyle={BoxStyle.Tight}
    >
      <HorizontalStack verticalAlign="top" style={{ height: "100%" }}>
        <MiniMap
          latitude={props.location.latitudeLongitude?.latitude}
          longitude={props.location.latitudeLongitude?.longitude}
          postalCode={postalCode}
          stateOrProvinceCode={stateOrProvinceCode}
          city={city}
          width={384}
          height={160}
        />
        <Spacer width={56} />
        <Stack align="left">
          <HorizontalStack align="spread" width="100%">
            <BusinessName>{businessName}</BusinessName>
          </HorizontalStack>
          <Spacer height={16} />
          <HorizontalStack width="100%" verticalAlign="top">
            <Stack align="left" width="174px">
              <SectionLabel>Address</SectionLabel>
              <SectionLine>{address.addressLine}</SectionLine>
              {address.addressLine2 && (
                <SectionLine>{address.addressLine2}</SectionLine>
              )}
              <SectionLine>{address.city}</SectionLine>
              <SectionLine>
                {fullStateOrProvinceName(address.stateOrProvinceCode || "")}
              </SectionLine>
              <SectionLine>{address.postalCode}</SectionLine>
              <Spacer height={8} />
              <SectionLabel>Location Type</SectionLabel>
              <SectionLine>{locationTypeDescription}</SectionLine>
            </Stack>

            <Stack align="left" width="138px">
              <SectionLabel>Special Services</SectionLabel>
              {accessorials.map((a, index) => {
                return <SectionLine key={index}>{a}</SectionLine>;
              })}
              <Spacer height={8} />
              <SectionLabel>Hours</SectionLabel>
              <SectionLine>{hoursDescription}</SectionLine>
            </Stack>
          </HorizontalStack>
        </Stack>
      </HorizontalStack>
    </Box>
  );
}
export default LocationBox2;
