import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UUID } from "../../../Cargo/Types/types";
import { AddBrokerBrokerType } from "../../Brokers/Types/brokerTypes";
import { AddContactContactType } from "../../Contacts/Types/contactTypes";
import {
  emptyLineItem,
  lineItemFromSavedCommodity,
} from "../../LineItems/Helpers/emptyLineItem";
import { recommendFreightClassFromDimensions } from "../../LineItems/Helpers/recommendFreightClassFromDimensions";
import { AddLineItemType } from "../../LineItems/Types/lineItemTypes";
import { AddLocationLocationType } from "../../Locations/Types/locationTypes";
import {
  Broker,
  EmergencyContactDetails,
  LineItem,
  LocationType,
  PreBookingShipment,
  Quote,
  SavedCommodity,
  SavedLocation,
} from "@freightsimple/generated-dashboard-openapi-client";
import { Currency } from "@freightsimple/generated-dashboard-openapi-client";
import { ensureAccessorialsContain } from "../../../Helpers/ensureAccessorialsContain";
import { formatPostalCode } from "../../../Helpers/formatPostalCode";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { v4 as uuidv4 } from "uuid";
import { nextBusinessDay } from "../Helpers/nextBusinessDay";

// Also update the code in AccessorialInput.tsx to ensure we challenge removal of these accessorials
export function generateRecommendedAccessorialsForLocation(
  locationType: LocationType,
  accessorials: string[],
) {
  // Ensure that when a distribution warehouse is selected, that appointment is enforced
  if (locationType === LocationType.DistributionWarehouse) {
    return ensureAccessorialsContain(accessorials, [
      "SCHEDULING_APPOINTMENT_REQUIRED",
    ]);
  } else if (locationType === LocationType.Residential) {
    return ensureAccessorialsContain(accessorials, [
      "SCHEDULING_APPOINTMENT_REQUIRED",
      "LOGISTICS_LIFT_GATE_REQUIRED",
    ]);
  } else {
    return accessorials;
  }
}

export interface BookShipmentShipmentType {
  shipmentId: string;
  selectedQuote?: Quote | undefined;
  lineItems: Array<AddLineItemType>;
  pickupDate: string;
  pickupLocation: AddLocationLocationType;
  deliveryLocation: AddLocationLocationType;
  pickupContact?: AddContactContactType;
  deliveryContact?: AddContactContactType;
  broker?: AddBrokerBrokerType | undefined;
  stripePaymentMethodId?: string | undefined;
  isReviewingPriorToBookingShipment: boolean;
  cheapestQuote?: Quote | undefined;
  pickupReferenceNumber: string;
  deliveryReferenceNumber: string;
  pickupBoothNumber: string | undefined;
  deliveryBoothNumber: string | undefined;
  finalizeBookingStages: Array<string>;
  addInsuranceToShipment: boolean;
  insuranceAmount: number;
  insuranceCurrency: Currency;
  branchId?: string;
  deliveryDeadline?: string | undefined;
  pickupDeadline?: string | undefined;
}

export interface BookShipmentSlice2Type {
  shipment: BookShipmentShipmentType;

  hasDeliveryDeadline: boolean;
  hasPickupDeadline: boolean;

  loadingShipment: boolean;
  shipmentError: string | undefined;
  creditCardBrand: string | undefined;

  enteringNewPickup: boolean;
  enteringNewDelivery: boolean;
  selectedPickupSavedLocationId: string | undefined;
  selectedDeliverySavedLocationId: string | undefined;

  isModifying: boolean;

  isCurrentScreenDirty: boolean;

  completedFlowItems: Array<string>;
  makePickupLocationAFavourite: boolean;
  makeDeliveryLocationAFavourite: boolean;

  // Note: There is deliberately no slice version
  // This slice should NOT be stored to local storage because
  // a) It is pre-quoting, so not a big deal if you lose the info you entered
  // b) Or, the info is coming from the API (and keeping local storage and API in sync would be hard)
}

function emptyLocationInfo(): AddLocationLocationType {
  return {
    locationType: LocationType.Warehouse,
    distributionWarehouseBrand: undefined,
    latitudeLongitude: undefined,
    address: {},
    accessorials: [],
    notes: "",
    hours: {
      openFrom: "10:00",
      openUntil: "16:00",
    },
  };
}

export function emptyEmergencyContactDetails(): EmergencyContactDetails {
  return {
    contactName: "",
    contactPhoneNumber: "",
    contactPhoneNumberExtension: "",
  };
}

function emptyShipment(): BookShipmentShipmentType {
  return {
    shipmentId: uuidv4(),
    selectedQuote: undefined,
    lineItems: [],
    pickupDate: nextBusinessDay().format("YYYY-MM-DD"),
    pickupLocation: emptyLocationInfo(),
    deliveryLocation: emptyLocationInfo(),
    pickupContact: undefined,
    deliveryContact: undefined,
    broker: undefined,
    stripePaymentMethodId: undefined,
    isReviewingPriorToBookingShipment: false,
    pickupReferenceNumber: "",
    deliveryReferenceNumber: "",
    pickupBoothNumber: undefined,
    deliveryBoothNumber: undefined,
    finalizeBookingStages: [],
    addInsuranceToShipment: false,
    insuranceAmount: 0,
    insuranceCurrency: Currency.Cad,
    branchId: undefined,
    deliveryDeadline: undefined,
    pickupDeadline: undefined,
  };
}

function emptyBookShipmentSlice2(): BookShipmentSlice2Type {
  return {
    shipment: emptyShipment(),
    loadingShipment: false,
    shipmentError: undefined,
    creditCardBrand: undefined,
    enteringNewPickup: true,
    enteringNewDelivery: true,
    selectedPickupSavedLocationId: undefined,
    selectedDeliverySavedLocationId: undefined,
    isModifying: false,
    isCurrentScreenDirty: false,
    completedFlowItems: [],
    makePickupLocationAFavourite: false,
    makeDeliveryLocationAFavourite: false,
    hasDeliveryDeadline: false,
    hasPickupDeadline: false,
  };
}

function initialState(): BookShipmentSlice2Type {
  return emptyBookShipmentSlice2();
}

function findSavedLocationForLocation(
  location: AddLocationLocationType,
  savedLocations: Array<SavedLocation>,
) {
  return savedLocations.findIndex(function (sl) {
    return (
      sl.location.businessName === location.businessName &&
      location.address.postalCode &&
      formatPostalCode(sl.location.address.postalCode) ===
        formatPostalCode(location.address.postalCode)
    );
  });
}

export const bookShipmentSlice = createSlice({
  name: "bookShipment",
  initialState: initialState(),
  reducers: {
    resetShipment(
      _state,
      action: PayloadAction<{
        savedLocations: Array<SavedLocation>;
        defaultPickupLocationId: UUID | undefined;
        defaultDeliveryLocationId: UUID | undefined;
      }>,
    ) {
      const newState = initialState();

      const {
        savedLocations,
        defaultPickupLocationId,
        defaultDeliveryLocationId,
      } = action.payload;

      if (savedLocations.length === 0) {
        newState.enteringNewPickup = true;
        newState.selectedPickupSavedLocationId = undefined;
        newState.shipment.pickupLocation = emptyLocationInfo();

        newState.enteringNewDelivery = true;
        newState.selectedDeliverySavedLocationId = undefined;
        newState.shipment.deliveryLocation = emptyLocationInfo();
      }

      if (savedLocations.length > 0) {
        if (defaultPickupLocationId !== undefined) {
          newState.enteringNewPickup = false;
          newState.selectedPickupSavedLocationId = defaultPickupLocationId;

          const defaultLocation = savedLocations.find(
            (sl) => sl.savedLocationId === defaultPickupLocationId,
          );

          if (defaultLocation === undefined) {
            throw new Error("Missing pickup location");
          }

          newState.shipment.pickupLocation = defaultLocation?.location;
        } else {
          newState.enteringNewPickup = true;
          newState.selectedPickupSavedLocationId = undefined;
          newState.shipment.pickupLocation = emptyLocationInfo();
        }

        if (defaultDeliveryLocationId !== undefined) {
          newState.enteringNewDelivery = false;
          newState.selectedDeliverySavedLocationId = defaultDeliveryLocationId;

          const defaultLocation = savedLocations.find(
            (sl) => sl.savedLocationId === defaultDeliveryLocationId,
          );

          if (defaultLocation === undefined) {
            throw new Error("Missing pickup location");
          }

          newState.shipment.deliveryLocation = defaultLocation?.location;
        } else {
          newState.enteringNewDelivery = true;
          newState.selectedDeliverySavedLocationId = undefined;
          newState.shipment.deliveryLocation = emptyLocationInfo();
        }
      }

      return newState;
    },

    loadFromShipment(
      state,
      action: PayloadAction<{
        shipment: PreBookingShipment;
        savedLocations: Array<SavedLocation>;
      }>,
    ) {
      const previouslyCompletedFlowItems = [...state.completedFlowItems];
      const preserveCompletedFlowItems =
        state.shipment.shipmentId === action.payload.shipment.shipmentId;

      const newShipment = action.payload.shipment;
      const savedLocations = action.payload.savedLocations;
      const newState = initialState();

      newState.hasDeliveryDeadline = newShipment.deliveryDeadline !== undefined;
      newState.hasPickupDeadline = newShipment.pickupDeadline !== undefined;

      newState.shipment = newShipment;

      // The postal code which comes from the shipment isn't formatted
      const newPickupPostalCode = newShipment.pickupLocation.address.postalCode;
      if (newPickupPostalCode !== undefined) {
        newState.shipment = {
          ...newShipment,
          pickupLocation: {
            ...newShipment.pickupLocation,
            address: {
              ...newShipment.pickupLocation.address,
              postalCode: formatPostalCode(newPickupPostalCode),
            },
          },
        };
      }

      const newDeliveryPostalCode =
        newShipment.deliveryLocation.address.postalCode;
      if (newDeliveryPostalCode !== undefined) {
        newState.shipment = {
          ...newShipment,
          deliveryLocation: {
            ...newShipment.deliveryLocation,
            address: {
              ...newShipment.deliveryLocation.address,
              postalCode: formatPostalCode(newDeliveryPostalCode),
            },
          },
        };
      }

      const pickupIndex = findSavedLocationForLocation(
        newShipment.pickupLocation,
        savedLocations,
      );
      const deliveryIndex = findSavedLocationForLocation(
        newShipment.deliveryLocation,
        savedLocations,
      );

      if (pickupIndex !== -1) {
        // We found a match for the pickup location
        newState.enteringNewPickup = false;
        newState.selectedPickupSavedLocationId =
          savedLocations[pickupIndex].savedLocationId;
      } else {
        newState.enteringNewPickup = true;
        newState.selectedPickupSavedLocationId = undefined;
      }

      if (deliveryIndex !== -1) {
        // We found a match for the pickup location
        newState.enteringNewDelivery = false;
        newState.selectedDeliverySavedLocationId =
          savedLocations[deliveryIndex].savedLocationId;
      } else {
        newState.enteringNewDelivery = true;
        newState.selectedDeliverySavedLocationId = undefined;
      }

      newState.isModifying = false;

      if (preserveCompletedFlowItems) {
        newState.completedFlowItems = previouslyCompletedFlowItems;
      }

      function ensureCompletedItem(item: string) {
        if (!newState.completedFlowItems.includes(item)) {
          newState.completedFlowItems.push(item);
        }
      }

      ensureCompletedItem("details");

      if (newState.shipment.selectedQuote) {
        ensureCompletedItem("quotes");
      }

      if (newState.shipment.isReviewingPriorToBookingShipment) {
        ensureCompletedItem("pickup-address");
        ensureCompletedItem("delivery-address");
        ensureCompletedItem("broker");
        ensureCompletedItem("payment");
      }

      return newState;
    },

    loadFromShipmentToModify(
      _state,
      action: PayloadAction<{
        shipment: BookShipmentShipmentType | PreBookingShipment;
        savedLocations: Array<SavedLocation>;
      }>,
    ) {
      const newShipment = action.payload.shipment;
      const savedLocations = action.payload.savedLocations;
      const newState = initialState();

      newState.hasDeliveryDeadline = newShipment.deliveryDeadline !== undefined;
      newState.hasPickupDeadline = newShipment.pickupDeadline !== undefined;

      newState.shipment = {
        ...newShipment,
        lineItems: newShipment.lineItems,
        shipmentId: uuidv4(),
        selectedQuote: undefined,
        cheapestQuote: undefined,
      };

      // The postal code which comes from the shipment isn't formatted
      const newPickupPostalCode = newShipment.pickupLocation.address.postalCode;
      if (newPickupPostalCode !== undefined) {
        newState.shipment = {
          ...newShipment,
          pickupLocation: {
            ...newShipment.pickupLocation,
            address: {
              ...newShipment.pickupLocation.address,
              postalCode: formatPostalCode(newPickupPostalCode),
            },
          },
        };
      }

      const newDeliveryPostalCode =
        newShipment.deliveryLocation.address.postalCode;
      if (newDeliveryPostalCode !== undefined) {
        newState.shipment = {
          ...newShipment,
          deliveryLocation: {
            ...newShipment.deliveryLocation,
            address: {
              ...newShipment.deliveryLocation.address,
              postalCode: formatPostalCode(newDeliveryPostalCode),
            },
          },
        };
      }

      const pickupIndex = findSavedLocationForLocation(
        newShipment.pickupLocation,
        savedLocations,
      );
      const deliveryIndex = findSavedLocationForLocation(
        newShipment.deliveryLocation,
        savedLocations,
      );

      if (pickupIndex !== -1) {
        // We found a match for the pickup location
        newState.enteringNewPickup = false;
        newState.selectedPickupSavedLocationId =
          savedLocations[pickupIndex].savedLocationId;
      } else {
        newState.enteringNewPickup = true;
        newState.selectedPickupSavedLocationId = undefined;
      }

      if (deliveryIndex !== -1) {
        // We found a match for the delivery location
        newState.enteringNewDelivery = false;
        newState.selectedDeliverySavedLocationId =
          savedLocations[deliveryIndex].savedLocationId;
      } else {
        newState.enteringNewDelivery = true;
        newState.selectedDeliverySavedLocationId = undefined;
      }

      newState.isModifying = true;

      return newState;
    },

    setShipmentLoading(state, action: PayloadAction<boolean>) {
      state.loadingShipment = action.payload;
    },

    setShipmentError(state, action: PayloadAction<string>) {
      state.shipmentError = action.payload;
    },

    setPickupDate(state, action: PayloadAction<string>) {
      state.shipment.pickupDate = action.payload;
    },

    addLineItem(state) {
      state.shipment.lineItems.push(emptyLineItem());
    },

    addLineItemFromSavedCommodity(
      state,
      action: PayloadAction<SavedCommodity>,
    ) {
      state.shipment.lineItems.push(lineItemFromSavedCommodity(action.payload));
    },

    updateLineItem(
      state,
      action: PayloadAction<{
        lineItemId: UUID;
        value: Partial<LineItem>;
      }>,
    ) {
      const lineItemIndex = state.shipment.lineItems.findIndex((lineItem) => {
        return lineItem.lineItemId === action.payload.lineItemId;
      });

      if (lineItemIndex !== -1) {
        state.shipment.lineItems[lineItemIndex] = {
          ...state.shipment.lineItems[lineItemIndex],
          ...action.payload.value,
        };

        if (
          state.shipment.lineItems[lineItemIndex].nmfcItemNumber === undefined
        ) {
          const li = state.shipment.lineItems[lineItemIndex];
          if (
            li.weightPerHandlingUnit !== undefined &&
            li.length !== undefined &&
            li.width !== undefined &&
            li.height !== undefined
          ) {
            // With no NMFC code, we calculate the Freight Class
            state.shipment.lineItems[lineItemIndex].freightClass =
              recommendFreightClassFromDimensions(
                li.weightPerHandlingUnit,
                li.length,
                li.width,
                li.height,
              ).freightClass;
          }
        }
      }
    },

    removeLineItem(state, action: PayloadAction<UUID>) {
      state.shipment.lineItems = state.shipment.lineItems.filter((lineItem) => {
        return lineItem.lineItemId !== action.payload;
      });
    },

    // If someone enters, say, 30x60x48 it is better if we treat this as 60x30x48
    // We need to be careful not to rearrange as they are typing though, eg if they
    // enter 60x300 but the final zero is a typo, which they would immediately delete
    // So we should only run this when all the values are correctly entered
    rearrangeLengthWidth(state, action: PayloadAction<UUID>) {
      const lineItemIndex = state.shipment.lineItems.findIndex((lineItem) => {
        return lineItem.lineItemId === action.payload;
      });

      const lineItem = state.shipment.lineItems[lineItemIndex];

      if (lineItem === undefined) {
        console.error("No lineItem");
        return;
      }

      if (
        lineItem !== undefined &&
        lineItem.width !== undefined &&
        lineItem.length !== undefined
      ) {
        if (lineItem.width > lineItem.length) {
          const newLineItem = {
            ...lineItem,
            width: lineItem.length,
            length: lineItem.width,
          };

          // We need to swap
          state.shipment.lineItems[lineItemIndex] = newLineItem;
        }
      }
    },

    newPickupLocation(state) {
      state.shipment.pickupLocation = emptyLocationInfo();
    },

    newDeliveryLocation(state) {
      state.shipment.deliveryLocation = emptyLocationInfo();
    },

    setPickupLocation(state, action: PayloadAction<AddLocationLocationType>) {
      // Only introduce recommended accessorials if the location type changed in this
      const locationTypeChanged =
        state.shipment.pickupLocation.locationType !==
        action.payload.locationType;

      const modifiedPayload = {
        ...action.payload,
        accessorials: locationTypeChanged
          ? generateRecommendedAccessorialsForLocation(
              action.payload.locationType,
              action.payload.accessorials,
            )
          : action.payload.accessorials,
      };

      state.shipment.pickupLocation = modifiedPayload;
    },

    setDeliveryLocation(state, action: PayloadAction<AddLocationLocationType>) {
      // Only introduce recommended accessorials if the location type changed in this
      const locationTypeChanged =
        state.shipment.deliveryLocation.locationType !==
        action.payload.locationType;

      const modifiedPayload = {
        ...action.payload,
        accessorials: locationTypeChanged
          ? generateRecommendedAccessorialsForLocation(
              action.payload.locationType,
              action.payload.accessorials,
            )
          : action.payload.accessorials,
      };

      state.shipment.deliveryLocation = modifiedPayload;
    },

    setCreditCardBrand(state, action: PayloadAction<string>) {
      state.creditCardBrand = action.payload;
    },

    setSelectedPickupSavedLocationId(
      state,
      action: PayloadAction<UUID | undefined>,
    ) {
      state.selectedPickupSavedLocationId = action.payload;
    },

    setSelectedDeliverySavedLocationId(
      state,
      action: PayloadAction<UUID | undefined>,
    ) {
      state.selectedDeliverySavedLocationId = action.payload;
    },

    setEnteringNewPickup(state, action: PayloadAction<boolean>) {
      state.enteringNewPickup = action.payload;
    },

    setEnteringNewDelivery(state, action: PayloadAction<boolean>) {
      state.enteringNewDelivery = action.payload;
    },

    updateBroker(state, action: PayloadAction<Broker>) {
      state.shipment.broker = action.payload;
    },

    updateStripePaymentMethod(state, action: PayloadAction<string>) {
      state.shipment.stripePaymentMethodId = action.payload;
    },

    setCurrentScreenIsDirty(state) {
      state.isCurrentScreenDirty = true;
    },

    setCurrentScreenIsNotDirty(state) {
      state.isCurrentScreenDirty = false;
    },

    markFlowItemCompleted(state, action: PayloadAction<string>) {
      const items = [...state.completedFlowItems];
      if (!items.includes(action.payload)) {
        items.push(action.payload);
      }
      state.completedFlowItems = items;
    },

    setPickupReferenceNumber(state, action: PayloadAction<string>) {
      state.shipment.pickupReferenceNumber = action.payload;
    },

    setDeliveryReferenceNumber(state, action: PayloadAction<string>) {
      state.shipment.deliveryReferenceNumber = action.payload;
    },

    setPickupBoothNumber(state, action: PayloadAction<string>) {
      state.shipment.pickupBoothNumber = action.payload;
    },

    setDeliveryBoothNumber(state, action: PayloadAction<string>) {
      state.shipment.deliveryBoothNumber = action.payload;
    },

    setMakePickupLocationAFavourite(state, action: PayloadAction<boolean>) {
      state.makePickupLocationAFavourite = action.payload;
    },

    setMakeDeliveryLocationAFavourite(state, action: PayloadAction<boolean>) {
      state.makeDeliveryLocationAFavourite = action.payload;
    },

    onAddInsuranceToShipmentChanged(state, action: PayloadAction<boolean>) {
      state.shipment.addInsuranceToShipment = action.payload;
    },

    onSetInsuranceAmount(state, action: PayloadAction<number>) {
      state.shipment.insuranceAmount = action.payload;
    },

    onSetInsuranceCurrency(state, action: PayloadAction<Currency>) {
      state.shipment.insuranceCurrency = action.payload;
    },

    setBranchId(state, action: PayloadAction<string | undefined>) {
      state.shipment.branchId = action.payload;
    },

    setDeliveryDeadline(state, action: PayloadAction<string | undefined>) {
      state.shipment.deliveryDeadline = action.payload;
    },

    setPickupDeadline(state, action: PayloadAction<string | undefined>) {
      state.shipment.pickupDeadline = action.payload;
    },

    setHasPickupDeadline(state, action: PayloadAction<boolean>) {
      state.hasPickupDeadline = action.payload;
      if (!action.payload) {
        state.shipment.pickupDeadline = undefined;
      }
    },

    setHasDeliveryDeadline(state, action: PayloadAction<boolean>) {
      state.hasDeliveryDeadline = action.payload;
      if (!action.payload) {
        state.shipment.deliveryDeadline = undefined;
      }
    },
  },
});

export const {
  loadFromShipment,
  loadFromShipmentToModify,
  setShipmentLoading,
  setShipmentError,
  resetShipment,
  setPickupDate,
  addLineItem,
  addLineItemFromSavedCommodity,
  updateLineItem,
  removeLineItem,
  newPickupLocation,
  newDeliveryLocation,
  rearrangeLengthWidth,
  setPickupLocation,
  setDeliveryLocation,
  setCreditCardBrand,
  setSelectedPickupSavedLocationId,
  setSelectedDeliverySavedLocationId,
  setEnteringNewPickup,
  setEnteringNewDelivery,
  updateBroker,
  updateStripePaymentMethod,
  setCurrentScreenIsDirty,
  setCurrentScreenIsNotDirty,
  markFlowItemCompleted,
  setPickupReferenceNumber,
  setDeliveryReferenceNumber,
  setPickupBoothNumber,
  setDeliveryBoothNumber,
  setMakePickupLocationAFavourite,
  setMakeDeliveryLocationAFavourite,
  onAddInsuranceToShipmentChanged,
  onSetInsuranceAmount,
  onSetInsuranceCurrency,
  setBranchId,
  setDeliveryDeadline,
  setHasDeliveryDeadline,
  setPickupDeadline,
  setHasPickupDeadline,
} = bookShipmentSlice.actions;

export function useBookShipment2Slice() {
  return useSelector((state: RootState) => state.bookShipment);
}
