import AutoCompleteInput from "../../../Cargo/Controls/AutoCompleteInput";
import Input from "../../../Cargo/Controls/Input";
import Spacer from "../../../Cargo/Layout/Spacer";
import { ErrorMessageType } from "../../../Cargo/Validation";
import { describeLocationType } from "../../../Data/LocationTypes";
import {
  Address,
  LocationType,
} from "@freightsimple/generated-dashboard-openapi-client";

import styled from "styled-components";
import { countryCodeFromPostalCode } from "../Validators/errorMessageForPostalCode";
import { AddressErrors } from "../Validators/errorMessagesForAddress";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 520px;
`;

function generateBusinessNameWarningMessage(
  businessName: string,
  locationType: LocationType,
): ErrorMessageType {
  const lcbn = businessName.toLowerCase();

  if (lcbn.includes("farm") && locationType !== LocationType.Farm) {
    return "⚠️ This might be a Farm. Please specify Farm when quoting to get an accurate rate. Additional charges may apply is the wrong location type is specified.";
  }

  if (
    lcbn.includes("school") &&
    locationType !== LocationType.UniversityCollege
  ) {
    return "⚠️ This might be a School. Please specify Educational when quoting to get an accurate rate. Additional charges may apply is the wrong location type is specified.";
  }

  if (
    lcbn.includes("college") &&
    locationType !== LocationType.UniversityCollege
  ) {
    return "⚠️ This might be a College. Please specify Educational when quoting to get an accurate rate. Additional charges may apply is the wrong location type is specified.";
  }

  if (
    lcbn.includes("university") &&
    locationType !== LocationType.UniversityCollege
  ) {
    return "⚠️ This might be a University. Please specify Educational when quoting to get an accurate rate. Additional charges may apply is the wrong location type is specified.";
  }

  if (lcbn.includes("correctional") && locationType !== LocationType.Prison) {
    return "⚠️ This might be a Prison. Please specify Prison when quoting to get an accurate rate. Additional charges may apply is the wrong location type is specified.";
  }

  if (lcbn.includes("prison") && locationType !== LocationType.Prison) {
    return "⚠️ This might be a Prison. Please specify Prison when quoting to get an accurate rate. Additional charges may apply is the wrong location type is specified.";
  }

  if (lcbn.includes("military") && locationType !== LocationType.Military) {
    return "⚠️ This might be a Military base. Please specify Military when quoting to get an accurate rate. Additional charges may apply is the wrong location type is specified.";
  }

  if (lcbn.includes("army") && locationType !== LocationType.Military) {
    return "⚠️ This might be a Military base. Please specify Military when quoting to get an accurate rate. Additional charges may apply is the wrong location type is specified.";
  }

  if (lcbn.includes("airport") && locationType !== LocationType.Airport) {
    return "⚠️ This might be an Airport. Please specify Airport when quoting to get an accurate rate. Additional charges may apply is the wrong location type is specified.";
  }

  if (lcbn.includes("port") && locationType !== LocationType.Port) {
    return "⚠️ This might be a Port. Please specify Port when quoting to get an accurate rate. Additional charges may apply is the wrong location type is specified.";
  }

  if (lcbn.includes("church") && locationType !== LocationType.ReligiousSite) {
    return "⚠️ This might be a Church. Please specify Religious Site when quoting to get an accurate rate. Additional charges may apply is the wrong location type is specified.";
  }

  if (lcbn.includes("mosque") && locationType !== LocationType.ReligiousSite) {
    return "⚠️ This might be a Mosque. Please specify Religious Site when quoting to get an accurate rate. Additional charges may apply is the wrong location type is specified.";
  }

  if (lcbn.includes("temple") && locationType !== LocationType.ReligiousSite) {
    return "⚠️ This might be a Temple. Please specify Religious Site when quoting to get an accurate rate. Additional charges may apply is the wrong location type is specified.";
  }

  if (lcbn.includes("mine") && locationType !== LocationType.Mine) {
    return "⚠️ This might be a Mine. Please specify Mine when quoting to get an accurate rate. Additional charges may apply is the wrong location type is specified.";
  }

  if (lcbn.includes("storage") && locationType !== LocationType.SelfStorage) {
    return "⚠️ This might be a Self Storage facility. Please specify Self Storage when quoting to get an accurate rate. Additional charges may apply is the wrong location type is specified.";
  }

  if (lcbn.includes("hospital") && locationType !== LocationType.Medical) {
    return "⚠️ This might be a Hospital. Please specify Medical when quoting to get an accurate rate. Additional charges may apply is the wrong location type is specified.";
  }

  function potentialDistributionWarehouseName(): string | undefined {
    const terms = [
      ["amazon", "an Amazon"],
      ["walmart", "a Walmart"],
      ["costco", "a Costco"],
      ["cost co", "a Costco"],
      ["unfi", "an UNFI"],
    ];

    return terms.find((o) => lcbn.includes(o[0]))?.[1];
  }

  const pdwn = potentialDistributionWarehouseName();
  if (pdwn && locationType !== LocationType.DistributionWarehouse) {
    return `⚠️ This might be ${pdwn} location. Please specify Distribution Warehouse when quoting to get an accurate rate. Additional charges may apply is the wrong location type is specified.`;
  }

  return undefined;
}

interface AddressInputProps {
  businessName: string;
  businessNameChanged: (_: string) => void;
  address?: Partial<Address>;
  addressChanged: (_: Partial<Address>) => void;
  locationType: LocationType;
  distributionWarehouseBrand: string | undefined;
  enabled: boolean;
  addressErrorMessages: AddressErrors;
  businessNameErrorMessages: ErrorMessageType;
  forceValidation: boolean;
  addressOptions: Array<string>;
  displayName?: string;
  allowCityAndPostalCodeEditing: boolean;
  warnAboutPotentialBusinessNameErrors: boolean;
}

const AddressInput: React.FC<AddressInputProps> = (
  props: AddressInputProps,
) => {
  const enabled = props.enabled;

  const {
    forceValidation,
    addressErrorMessages,
    businessNameErrorMessages,
    allowCityAndPostalCodeEditing,
  } = props;

  const businessNameWarningMessage = props.warnAboutPotentialBusinessNameErrors
    ? generateBusinessNameWarningMessage(props.businessName, props.locationType)
    : undefined;

  const addressLabel =
    props.displayName === undefined
      ? "Address"
      : `${props.displayName} Address`;

  function describeBusinessName() {
    const substring = `Specified Location Type : ${describeLocationType(props.locationType, props.distributionWarehouseBrand)}`;
    if (props.locationType === LocationType.TradeShow) {
      return <>Trade Show Name</>;
    }

    if (props.locationType === LocationType.Residential) {
      return <>Business / Customer Name ({substring})</>;
    }
    return <>Business Name ({substring})</>;
  }

  return (
    <>
      <Row>
        <Input
          type="text"
          name="business_name"
          label={describeBusinessName()}
          value={props.businessName || ""}
          enabled={enabled}
          onChange={(value: string) => props.businessNameChanged(value)}
          required
          width={520}
          errorMessage={businessNameErrorMessages}
          warningMessage={businessNameWarningMessage}
          forceValidation={forceValidation}
          autoComplete="organization"
        ></Input>
      </Row>
      <Row>
        <AutoCompleteInput
          type="text"
          name="address_line"
          label={addressLabel}
          value={props.address?.addressLine || ""}
          enabled={enabled}
          onChange={(value: string) =>
            props.addressChanged({
              addressLine: value,
            })
          }
          errorMessage={addressErrorMessages.addressLine}
          forceValidation={forceValidation}
          autoComplete="nope" // https://stackoverflow.com/questions/12374442/chrome-ignores-autocomplete-off
          width={520}
          autoCompleteOptions={props.addressOptions}
        />
      </Row>
      <Row>
        <Input
          type="text"
          name="address_line_2"
          label={`Other details (eg. Unit / Floor / Door)`}
          value={props.address?.addressLine2 || ""}
          enabled={enabled}
          onChange={(value: string) =>
            props.addressChanged({
              addressLine2: value,
            })
          }
          errorMessage={addressErrorMessages.addressLine2}
          forceValidation={forceValidation}
          width={520}
        />
      </Row>
      <Row>
        <Input
          type="text"
          name="city"
          label={`City`}
          value={props.address?.city || ""}
          enabled={enabled}
          readOnly={!allowCityAndPostalCodeEditing}
          onChange={(value: string) =>
            props.addressChanged({
              city: value,
            })
          }
          width={250}
          errorMessage={addressErrorMessages.city}
          forceValidation={forceValidation}
        ></Input>
        <Spacer width={20} />
        <Input
          type="text"
          name="state_or_province_code"
          label={`State or Province`}
          value={props.address?.stateOrProvinceCode || ""}
          onChange={(value: string) =>
            props.addressChanged({
              stateOrProvinceCode: value.replace(/[^A-Za-z]/g, "").substr(0, 2),
            })
          }
          enabled={enabled}
          readOnly={!allowCityAndPostalCodeEditing}
          width={115}
          errorMessage={addressErrorMessages.stateOrProvinceCode}
          forceValidation={forceValidation}
        ></Input>
        <Spacer width={20} />
        <Input
          type="text"
          name="postal_code"
          label={`Postal Code`}
          value={props.address?.postalCode || ""}
          onChange={function (value: string) {
            const countryCode = countryCodeFromPostalCode(value);
            if (countryCode !== undefined) {
              props.addressChanged({
                postalCode: value,
                countryCode,
              });
            } else {
              props.addressChanged({
                postalCode: value,
              });
            }
          }}
          enabled={enabled}
          readOnly={!allowCityAndPostalCodeEditing}
          width={115}
          errorMessage={addressErrorMessages.postalCode}
          forceValidation={forceValidation}
        ></Input>
      </Row>
    </>
  );
};
export default AddressInput;
